import React, {Component} from 'react';
import {AmigosContainer, ButtonContainer, CheckMark, CustomCheckbox, CustomMenuItem, FieldTitle, FormContainer, ImageContainer, ImageContentContainer, ImageInput, InviteAmigo, InviteContainer, Layout, LocationContainer, NameContainer, PageTitle, RemovePhotoButton, ScrollableContainer, TitleContainer, TopContainer} from "../CreateEvent/styles";
import {checkImage, getOrientation, resetOrientation, checkMultipleImage} from "../../utils/methods";
import {toast} from "react-toastify";
import {imageErrorMessage} from "../../utils/constants";
// import { mealTypes, skillLevel } from '../../../mockData.json';
import {ShowIngredientContent, ShowDirectionContent, TimeContainer, AddButton, CustomInputIngredients, FieldTitleIngredients, IngredientButtonContainer, MultipleImageContainer, ColonSeparator, RadioButtonContainer, ActivityTypeContainer, ActivityDropdown} from './styles';
import PropTypes from "prop-types";
import {getRecipeCategories, addRecipe} from "../../redux/actions";
import {connect} from "react-redux";
import Waiting from "../Waiting";
import RadioButton from "../CustomRadioButton";
import {FirstPart} from "../BehaviorChallenge/styles";
import {withTranslation} from 'react-i18next';
import CommonInputBox from '../common/CommonInputBox/CommonInputBox';
import CommonTextArea from '../common/CommonTextArea/CommonTextArea';
import CommonInputTime from '../common/CommonInputTime/CommonInputTime';
import CommonButton from '../common/CommonButton/CommonButton';

let fileArray = [];
class CreateRecipe extends Component{
  constructor(props) {
    super(props);
    this.state = {
      title: '',
      imgSrc: '',
      imageName: '',
      level:"Easy - Sous Chef",
      Blurb: '',
      mealType: "Breakfast",
      cookTimeHours: "00",
      cookTimeMins: "00",
      prepTimeHours: "00",
      prepTimeMins: "00",
      video:'',
      recipeCategorieslist: [],
      servings: '',
      showContent:'',
      ingredientsContent: '',
      stepCount: 0,
      Ingredients:[],
      directions:[],
      imageFiles:[],
      editedContent: [],
      editSteps:[],
      description:'',
      error: [],
      selectedOption: 'cookTime',
      selectedThemeOption: 0,
      themeName: '',
      themeMonth: '',
      themeId: null
    };
  }
  mealTypes= [
    "Breakfast",
    "Lunch",
    "Dinner",
    "Snack",
    "Dessert"
  ]

  skillLevel= [
    "Easy - Sous Chef",
    "Medium - Head Chef",
    "Hard - Executive Chef"
  ]

  componentDidMount() {
    const{getRecipeCategories} = this.props;
    getRecipeCategories();
  }
  onChangeInput = (e) => {
    if(e.target.value !== ' ') {
      if(e.target.name === 'servings'){
        this.setState({
          [e.target.name]: e.target.value.replace(/[&\\#,+()$~%@^!_.'":*?<>{}]/g, "")
        });
      } else if(e.target.name === 'cookTimeMins' || e.target.name === 'cookTimeHours' ||  e.target.name === 'prepTimeMins' || e.target.name === 'prepTimeHours'){
        let value = (e.target.name === 'cookTimeMins' || e.target.name === 'prepTimeMins') ? Math.min(e.target.value , 59) :  Math.min(e.target.value , 23);
        this.setState({
          [e.target.name]: value,
        });
      } else
      {
        this.setState({
          [e.target.name]: e.target.value,
        });
      }
    }
  };

  onChange = (e) => {
    let file = e.target.files[0];
    let fileArr = e.target.files;
    if (e.target.files[0]) {
      if (checkImage(fileArr)) {
        if ((file?.size / 1000000) <= 20) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          const array = document.getElementById('recipe-upload-file').value.split("\\");
          reader.onloadend = () => {
            this.setState({
              imgSrc: reader.result,
              imageName: array[array.length - 1],
            });
            getOrientation(file, (or) => {
              resetOrientation([reader.result], or, (baseImage) => {
                this.setState({
                  imgSrc: baseImage
                });
              });
            });
          };
        } else {
          toast.error('Please select image file less than 20MB');
          document.getElementById('recipe-upload-file').value = '';
        }
      } else {
        toast.error(imageErrorMessage);
        document.getElementById('recipe-upload-file').value = '';
      }
    }
  };
  removePhoto = () => {
    document.getElementById('recipe-upload-file').value = '';
    this.setState({
      imgSrc: '',
      imageName: '',
    });
  };
  removeRecipeImages = (index) => {
    const{imageFiles} = this.state;
    if(imageFiles.length > 0){
      imageFiles.splice(index,1);
    }
    this.setState({
      imageFiles: imageFiles
    });
  };
  onSelectTypes = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  onChangeCategory = (id) => {
    const { recipeCategorieslist } = this.state;
    if(recipeCategorieslist.includes(id)) {
      let index = recipeCategorieslist.findIndex((item) => item === id);
      if(index > -1) {
        recipeCategorieslist.splice(index, 1);
      }
    } else {
      recipeCategorieslist.push(id);
    }
    this.setState({
      recipeCategorieslist: recipeCategorieslist
    })
  };

  selectAllCategories = (e, recipeCategories) => {
    e.preventDefault();
    const { recipeCategorieslist } = this.state;
    if(recipeCategorieslist.length === recipeCategories.length) {
      this.setState({
        recipeCategorieslist: []
      })
    }
    else {
      let arr = [];
      recipeCategories && recipeCategories.map((data) => {
        arr.push(data.id);
      });
      this.setState({
        recipeCategorieslist: arr
      })
    }
  };
  onChangeDirections = (e) => {
    if(e.target.value !== ' ') {
      this.setState({showContent: e.target.value})
    }
  };

  disableEdit = (index) => {
    const{directions, editSteps} = this.state;
    if(directions && (directions[index]['description'] !== '')) {
      directions.map((data, index) => {
        editSteps[index] = false;
      });
      this.setState({editSteps: editSteps, editedContent: []})
    }
  };

  onEditDirections = (e, index) => {
    const{directions, editedContent} = this.state;
    if(e.target.value !== ' ') {
      directions[index]['description'] = e.target.value;
      editedContent[index] = e.target.value;
      this.setState({editedContent: editedContent, directions: directions})
    }
  };
  editDirection = (directionIndex) => {
    const{editedContent, directions, editSteps} = this.state;
    directions.map((data, index)=>{
      editedContent[index] = data['description'];
      editSteps[index] = false;
    });
    editSteps[directionIndex] = true;
    this.setState({ editSteps: editSteps, editedContent: editedContent});
  };

  storeDirections = () => {
    const{showContent, directions, stepCount} = this.state;
    let arr = [...directions];
    let obj = {};
    obj['step'] = stepCount+1;
    obj['description'] = showContent.replace(/^\s\s*/, '').replace(/\s\s*$/, '');
    arr.push(obj);
    this.setState({directions: arr, showContent: '', stepCount: stepCount+1});
  };
  removeDirections = (directionId) => {
    const{directions} = this.state;
    if(directions.length > 0) {
      let index = directions.findIndex((item) => item.step === directionId);
      if(index > -1) {
        directions.splice(index, 1);
      }
    }
    let directionsArray = directions;
    directions.map((data, index) => {
      directionsArray[index]['step'] = index+1;
    });
    this.setState({directions: directionsArray, stepCount: directionsArray.length});
  };
  onChangeIngredients = (e) => {
    if(e.target.value !== ' ') {
      this.setState({ingredientsContent: e.target.value})
    }
  };
  storeIngredients = () => {
    const{ingredientsContent, Ingredients} = this.state;
    let arr = [];
    arr = [...Ingredients];
    arr.push(ingredientsContent);
    this.setState({Ingredients: arr, ingredientsContent: ''});
  };
  removeIngredients = (index) => {
    const{Ingredients} = this.state;
    Ingredients.splice(index, 1);
    this.setState({Ingredients: Ingredients});
  };

  onChangeMultiple = (e, click) => {
    const{imageFiles} = this.state;
    let fileArr = [...e.target.files];
    if ((this.state.imageFiles.length + fileArr.length) < 7 && fileArr.length < 7) {
      fileArr.map((imageFile) => {
        if (checkMultipleImage(imageFile)) {
          if ((imageFile?.size / 1000000) <= 20) {
            let reader = new FileReader();
            reader.readAsDataURL(imageFile);
            const fileName = imageFile.name;
            let obj = {imageName: '', imgSrc: ''};
            reader.onloadend = () => {
              getOrientation(imageFile, (or) => {
                resetOrientation([reader.result], or, (baseImage) => {
                  let existIndex = imageFiles.findIndex((data) => data['imageName'] == fileName);
                  if(existIndex == -1) {
                    obj['imageName'] = fileName;
                    obj['imgSrc'] = baseImage;
                    fileArray.push(obj);
                    let dubImageFiles = [...imageFiles, ...fileArray];
                    this.setState({imageFiles: dubImageFiles});
                  }
                });
              });
            };
          } else {
            toast.error('Please select image file less than 20MB');
            document.getElementById('multiple-recipe-upload-file').value = '';
          }
        } else {
          toast.error(imageErrorMessage);
          document.getElementById('multiple-recipe-upload-file').value = '';
        }
      });
      fileArray = [];
    } else {
      if(click) {
        toast.error('Please select maximum of 6 images');
      }
    }
  };

  onSelectTheme = (value) => {
    this.setState({
      themeName: value.theme_text,
      themeMonth: value.month,
      themeId: value.id,
    });
  };

  getminutes = (mins, hour) => {
    let hours = parseInt(hour) || 0;
    let minutes = parseInt(mins);
    let totalTime = 0;
    if(hours == 0 && minutes > 0){
      totalTime = minutes;
    }else {
      totalTime = (hours * 60) + minutes;
    }
    return totalTime;
  };
  toggleRadioButton = (e) => {
    this.setState({selectedOption: e.target.value}, ()=>{});
  };
  toggleThemeRadioButton = (e) => {
    this.setState({selectedThemeOption: parseInt(e.target.value)}, ()=>{});
  };
  saveRecipe = (e) => {
    e.preventDefault();
    const{history, addRecipe, isAdminRoute} = this.props;
    const {title, imgSrc, Blurb, servings, mealType, level, video, recipeCategorieslist, Ingredients, directions, imageFiles, description, cookTimeMins, cookTimeHours, prepTimeHours, prepTimeMins, selectedOption, themeId} = this.state;
    let obj = {};
    let dubImageFiles = [];
    imageFiles && imageFiles.length > 0 ? imageFiles.map((data)=>{dubImageFiles.push(data['imgSrc'])}) : [];
    obj['name'] = title;
    obj['image'] = imgSrc;
    obj['title'] = Blurb;
    obj['description'] = description;
    obj['servings'] = servings;
    obj['meal_type'] = mealType;
    obj['level'] = level;
    obj['prep_time'] = this.getminutes(prepTimeMins, prepTimeHours);
    obj['cook_time'] = selectedOption === 'cookTime' ? this.getminutes(cookTimeMins, cookTimeHours) : 'Refrigerate overnight or at least 4 hours';
    obj['video_url'] = video;
    obj['category_tags'] = recipeCategorieslist;
    obj['ingredients'] = Ingredients;
    obj['directions'] = directions;
    obj['recipe_images'] = dubImageFiles;
    obj['cook_time_option'] = selectedOption === 'cookTime' ? 0 : 1;
    obj['theme_id'] = themeId;
    addRecipe(obj, history, isAdminRoute);
  };
  render() {
    const{title, imgSrc, imageName, Blurb, servings, mealType, level, video, recipeCategorieslist, Ingredients, ingredientsContent, showContent, directions, imageFiles, editSteps, editedContent, description, cookTimeMins, cookTimeHours, prepTimeHours, prepTimeMins, selectedOption, selectedThemeOption, themeName, themeMonth} = this.state;
    const{recipeCategories, recipeLoading, /* themes, */ t, themesHeaderAll} = this.props;
    if(!recipeCategories || recipeLoading){
      return <Waiting />;
    }
    let isDisabled = false;
    if(description === '' || title === '' || imgSrc === '' || imageName === '' || Blurb === '' || servings === '' || mealType === '' || level === '' || ((prepTimeHours > 0) ? false : prepTimeMins <= 0) || (selectedOption === 'cookTime' ? (cookTimeHours > 0 ? false : cookTimeMins <= 0) : false) ||video === '' || recipeCategorieslist.length <= 0 || Ingredients.length <= 0 || directions.length <= 0 || editedContent.length > 0){
      isDisabled = true;
    }
    return (
      <Layout>
        <PageTitle>{t("Create Recipe")}</PageTitle>
        <div style={{float: 'left', width: '100%'}}>
          <FormContainer onSubmit={this.saveRecipe}>
            <TitleContainer>
              <FieldTitle>{t("Recipe Title")}<span style={{color: 'red'}}>*</span>:</FieldTitle>
              {/* <StyledInput
                placeholder={t("Type title here...")}
                name="title"
                onChange={this.onChangeInput}
                value={title}
                maxLength="100"
                type="text"
              /> */}
              <CommonInputBox
                placeholder={t("Type title here...")}
                name="title"
                onChange={this.onChangeInput}
                value={title}
                maxLength="100"
                type="text"
              />
            </TitleContainer>
            <ImageContainer>
              <FieldTitle>{t("Main Recipe Picture")}<span style={{color: 'red'}}>*</span>:</FieldTitle>
              <div>
                { imageName &&
              <div>
                <img src={imgSrc} />
                <ImageContentContainer>
                  <div>{imageName}</div>
                  <RemovePhotoButton color="#aaaaaa" type="button" onClick={this.removePhoto}>{t("Remove photo")}</RemovePhotoButton>
                </ImageContentContainer>
              </div>
                }
                <ImageInput htmlFor="recipe-upload-file" showAtBottom={imageName !== ''}>
                  {imageName ? t('browse new') : t('browse')}
                  <input
                    id="recipe-upload-file"
                    type="file"
                    name="recipeImage"
                    accept=".jpeg, .png, .jpg"
                    multiple={false}
                    onChange={(e) => this.onChange(e)}
                    onClick={(e) => e.target.files[0] && this.onChange(e)}
                  />
                </ImageInput>
              </div>
            </ImageContainer>
            <TitleContainer>
              <FieldTitle>{t("Amped with")}<span style={{color: 'red'}}>*</span>:</FieldTitle>
              {/* <StyledInput
                placeholder={t("Type Amped with here...")}
                name="Blurb"
                onChange={this.onChangeInput}
                value={Blurb}
                maxLength="100"
                type="text"
              /> */}
              <CommonInputBox
                placeholder={t("Type Amped with here...")}
                name="Blurb"
                onChange={this.onChangeInput}
                value={Blurb}
                maxLength="100"
                type="text"
              />
            </TitleContainer>
            <TitleContainer>
              <FieldTitle>{t("Servings")}<span style={{color: 'red'}}>*</span>:</FieldTitle>
              {/* <StyledInput
                placeholder={t("Type Servings here...")}
                name="servings"
                onChange={this.onChangeInput}
                value={servings}
                maxLength="100"
                type="text"
              /> */}
              <CommonInputBox
                placeholder={t("Type Servings here...")}
                name="servings"
                onChange={this.onChangeInput}
                value={servings}
                maxLength="100"
                type="text"
              />
            </TitleContainer>
            <TitleContainer>
              <FieldTitle>{t("Prep Time")}<span style={{color: 'red'}}>*</span>: </FieldTitle>
              <TimeContainer>
                <CommonInputTime
                  placeholder="00"
                  name="prepTimeHours"
                  onChange={this.onChangeInput}
                  value={prepTimeHours}
                  type="number"
                  min="0"
                  max="23"
                  border={0}
                  pattern="\d{2}"
                />
                <ColonSeparator>:</ColonSeparator>
                <CommonInputTime
                  placeholder="00"
                  name="prepTimeMins"
                  onChange={this.onChangeInput}
                  value={prepTimeMins}
                  type="number"
                  min="0"
                  max="59"
                  border={1}
                  pattern="\d{2}"
                />
              </TimeContainer>
            </TitleContainer>
            <TitleContainer>
              <FieldTitle>{t("Cook Time")}<span style={{color: 'red'}}>*</span>:</FieldTitle>
              <RadioButtonContainer>
                <RadioButton id="1" handler={this.toggleRadioButton} value={'cookTime'} isChecked={selectedOption === 'cookTime'} label={t('Input Cook Time')} challengeLeaderBoard={true}/>
                <RadioButton id="2" handler={this.toggleRadioButton} value={'refrigerate'} isChecked={selectedOption === 'refrigerate'} label={t('Refrigerate Overnight or at least 4 hours')} challengeLeaderBoard={true}/>
              </RadioButtonContainer>
              {selectedOption === 'cookTime' ?
                <TimeContainer>
                  <CommonInputTime
                    placeholder="00"
                    name="cookTimeHours"
                    onChange={this.onChangeInput}
                    value={cookTimeHours}
                    type="number"
                    min="0"
                    max="23"
                    border={0}
                    pattern="\d{2}"
                  />
                  <ColonSeparator>:</ColonSeparator>
                  <CommonInputTime
                    placeholder="00"
                    name="cookTimeMins"
                    onChange={this.onChangeInput}
                    value={cookTimeMins}
                    type="number"
                    min="0"
                    max="59"
                    border={1}
                    pattern="\d{2}"
                  />
                </TimeContainer> : null
              }
            </TitleContainer>
            <TitleContainer>
              <ActivityTypeContainer>
                <FieldTitle>{t("Meal Type")}<span style={{color: 'red'}}>*</span>:</FieldTitle>
                <ActivityDropdown
                  title={mealType}
                  id="dropdown-meal"
                  isCreateRecipe={1}
                  onSelect={(index,/*item*/) =>{this.onSelectTypes('mealType', this.mealTypes[index]);}}
                >
                  {
                    this.mealTypes.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        active={mealType === item}
                      >
                        {t(item)}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>
            </TitleContainer>
            <TitleContainer>
              <ActivityTypeContainer>
                <FieldTitle>{t("Skill Level")}<span style={{color: 'red'}}>*</span>:</FieldTitle>
                <ActivityDropdown
                  title={level}
                  id="dropdown-level"
                  onSelect={(index) => this.onSelectTypes('level', this.skillLevel[index])}
                >
                  {
                    this.skillLevel.map((item, index) => (
                      <CustomMenuItem
                        eventKey={index}
                        key={index}
                        active={level === item}
                      >
                        {t(item)}
                      </CustomMenuItem>
                    ))
                  }
                </ActivityDropdown>
              </ActivityTypeContainer>
            </TitleContainer>

            <AmigosContainer>
              <TopContainer>
                <FieldTitle>{t("Category Tags – Select all the apply")}<span style={{color: 'red'}}>*</span>:</FieldTitle>
                {recipeCategories && recipeCategories.length > 0 &&
                <ButtonContainer>
                  {/* <SaveButton color="#159fc9" onClick={(e) => this.selectAllCategories(e, recipeCategories)}>
                    <i className="fas fa-check"/>
                    {
                      recipeCategorieslist.length === recipeCategories.length ? t('deselect all categories') : t('select all categories')
                    }
                  </SaveButton> */}
                  <CommonButton
                    btnType={"rounded"}
                    onClick={(e) => this.selectAllCategories(e, recipeCategories)}
                    icon={<i className="fas fa-check"/>}
                    title={recipeCategorieslist.length === recipeCategories.length ? t('Deselect all categories') : t('Select all categories')}
                    styles={{color:"#159fc9"}}
                  />
                </ButtonContainer>
                }
              </TopContainer>
              <InviteContainer>
                <ScrollableContainer>
                  <LocationContainer minHeight={recipeCategories && recipeCategories.length}>
                    {
                      recipeCategories && recipeCategories.length > 0 ? recipeCategories.map((tag, index) => (

                        <InviteAmigo key={index}>
                          <CustomCheckbox>
                            <input
                              type="checkbox"
                              checked={recipeCategorieslist.includes(tag['id'])}
                              onChange={() => this.onChangeCategory(tag['id'])}
                            />
                            <CheckMark checked={recipeCategorieslist.includes(tag['id'])} />
                          </CustomCheckbox>
                          <NameContainer onClick={() => this.onChangeCategory(tag['id'])}>
                            <span>{t(tag['recipe_tag'])}</span>
                          </NameContainer>
                        </InviteAmigo>

                      )) : null
                    }
                  </LocationContainer>
                </ScrollableContainer>
              </InviteContainer>
            </AmigosContainer>

            <TitleContainer>
              <FieldTitle marginTop="20px">{t("Recipe Video Link")}<span style={{color: 'red'}}>*</span>:</FieldTitle>
              <CommonInputBox 
                placeholder={t("http://")}
                name="video"
                type="url"
                pattern="https?://.+"
                onChange={this.onChangeInput}
                value={video}
              />
            </TitleContainer>
            <TitleContainer>
              <FieldTitleIngredients>{t("Ingredients")}<span style={{color: 'red'}}>*</span>:</FieldTitleIngredients>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <CustomInputIngredients
                  placeholder={t("Type Ingredients here...")}
                  name="Ingredients"
                  onChange={this.onChangeIngredients }
                  value={ingredientsContent}
                  type="text"
                />
                { ingredientsContent.replace(/^\s\s*/, '').replace(/\s\s*$/, '') && <IngredientButtonContainer><AddButton onClick={this.storeIngredients}>{t("Add Ingredients")}</AddButton></IngredientButtonContainer>}
              </div>
              {Ingredients.map((data,index) => (
                <ShowIngredientContent key={index}>
                  <div>
                    <div>{t(data)}</div>
                    <i className="fa fa-times" aria-hidden="true" onClick={() => this.removeIngredients(index)}/>
                  </div>
                </ShowIngredientContent>
              ))}
            </TitleContainer>
            <TitleContainer>
              <FieldTitleIngredients>{t("Directions")}<span style={{color: 'red'}}>*</span>:</FieldTitleIngredients>
              <div style={{display: 'flex', flexDirection: 'row'}}>
                <CustomInputIngredients
                  placeholder={t("Type Directions here...")}
                  name="Directions"
                  onChange={this.onChangeDirections}
                  value={showContent}
                  type="text"
                />
                { showContent.replace(/^\s\s*/, '').replace(/\s\s*$/, '') && <IngredientButtonContainer><AddButton onClick={this.storeDirections}>{t("Add Direction")}</AddButton></IngredientButtonContainer>}
              </div>
              {directions.map((data, index) => (
                <ShowDirectionContent key={index}>
                  <div>{`step ${data['step']}`}</div>
                  {editSteps[index] ?
                    <div>
                      <CustomInputIngredients
                        placeholder="Type Directions here..."
                        name="editDirections"
                        onChange={(e)=>this.onEditDirections(e, index)}
                        value={editedContent[index]}
                        onMouseLeave={() => this.disableEdit(index)}
                        type="text"
                      />
                    </div>
                    :
                    <div>
                      <div onClick={()=>this.editDirection(index)}>{data['description']}</div>
                      <i className="fa fa-times" aria-hidden="true" onClick={() => this.removeDirections(data['step'])}/>
                    </div>
                  }
                </ShowDirectionContent>
              ))}
            </TitleContainer>
            <ImageContainer>
              <FieldTitle>{t("Upload Other Images")}:</FieldTitle>
              <div>
                { imageFiles && imageFiles.length > 0 ?
                  imageFiles.map((data, index) => (
                    <MultipleImageContainer key={index}>
                      <img src={data.imgSrc} alt={data.imageName}/>
                      <ImageContentContainer>
                        <div>{data.imageName}</div>
                        <RemovePhotoButton color="#aaaaaa" type="button" onClick={() => this.removeRecipeImages(index)}>{t("Remove photo")}</RemovePhotoButton>
                      </ImageContentContainer>
                    </MultipleImageContainer>
                  )) : null
                }
                {imageFiles.length < 6 ? <ImageInput htmlFor="multiple-recipe-upload-file">
                  {imageFiles.length > 0 ? t('browse new') : t('browse')}
                  <input
                    id="multiple-recipe-upload-file"
                    type="file"
                    name="images"
                    accept=".jpeg, .png, .jpg"
                    multiple={true}
                    onChange={(e) => this.onChangeMultiple(e,1)}
                    onClick={(e) => e.target.files[0] && this.onChangeMultiple(e,0)}
                  />
                </ImageInput> : null
                }
              </div>
            </ImageContainer>
            <TitleContainer>
              <FieldTitle>{t("Recipe Description")}<span style={{color: 'red'}}>*</span>:</FieldTitle>
              <CommonTextArea
                placeholder={t("Write your description here...")}
                rows="8"
                name="description"
                value={description}
                onChange={this.onChangeInput}
                data-gramm_editor="false"
                type="text"
              />
            </TitleContainer>
            <ImageContainer>
              <FirstPart>
                <FieldTitle>{t("Add a Theme")}:</FieldTitle>
                <RadioButtonContainer justifyContent={"start"}>
                  <RadioButton id="11" handler={this.toggleThemeRadioButton} value={1} isChecked={selectedThemeOption == 1} label={t('Yes')} challengeLeaderBoard={true}/>
                  <RadioButton id="12" handler={this.toggleThemeRadioButton} value={0} isChecked={selectedThemeOption == 0} label={t('No')} challengeLeaderBoard={true}/>
                </RadioButtonContainer>
              </FirstPart>
            </ImageContainer>
            {selectedThemeOption === 1 ? <TitleContainer addMargin><ActivityTypeContainer width="100%" noChildWidth={1}>
              <FieldTitle>{t("Select a theme")}:</FieldTitle>
              <ActivityDropdown
                title={<div className="title">{themesHeaderAll.length === 0 ? t('No Themes Available') : (themeName === '' && themeMonth === '') ? t('Select a theme') : `${themeMonth}, ${themeName}`}</div>}
                id={"surveyType"}
              >{
                  themesHeaderAll && themesHeaderAll.length > 0 && themesHeaderAll.map((list) => (
                    <CustomMenuItem key={list.id} active={themeName == list.theme_text} /* onSelect */ onClick={() => this.onSelectTheme(list)}>{list.month}, {list.theme_text}</CustomMenuItem>
                  ))
                }
              </ActivityDropdown>
            </ActivityTypeContainer></TitleContainer> : null}
            <ImageContainer>
              {/* <SaveButton type="submit" disabled={isDisabled} addMargin>{t("Save Recipe")}</SaveButton> */}
              <CommonButton
                btnType={"rounded"}
                type={"submit"}
                disabled={isDisabled}
                styles={{width:'158px', height:'35px', marginBottom:'55px'}}
                addMargin
                title={t("Save Recipe")}
              />
            </ImageContainer>
          </FormContainer>
        </div>
      </Layout>
    );
  }
}

CreateRecipe.propTypes = {
  history: PropTypes.object,
  recipeCategories: PropTypes.array,
  getRecipeCategories:PropTypes.func,
  addRecipe: PropTypes.func,
  recipeLoading: PropTypes.bool,
  themes: PropTypes.array,
  themesHeaderAll: PropTypes.array,
  t: PropTypes.func,
  isAdminRoute: PropTypes.bool
};

const mapStateToProps = (state) => ({
  recipeCategories: state.education.selectedRecipeCategories,
  recipeLoading: state.education.recipeLoading
});

const mapDispatchToProps = (dispatch) => ({
  getRecipeCategories: () => dispatch(getRecipeCategories()),
  addRecipe: (data, history, isAdminRoute) => dispatch(addRecipe(data, history, isAdminRoute))
});

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(CreateRecipe));